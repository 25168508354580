//base html stuff
html,
body {
	@apply bg-white text-darkgreen font-body font-bold antialiased {}
	-webkit-overflow-scrolling: touch;
	text-rendering: optimizeLegibility;

	font-size: 100%;
	@screen sm {
		font-size: 110%; } }

* {
	box-sizing: border-box;
	&:focus {
		outline: none; } }

img {
	display: block;
	width: 100%; }

#preload-bar {
	@apply bg-white fixed w-full top-0 left-0 right-0 z-30 {}
	height: 3px;

	svg {
		height: 3px;
		width: 0px; }

	path {
		@apply stroke-current text-black {} } }

#preload-counter {
	@apply fixed z-30 top-0 right-0 mt-1 mr-1 pointer-events-none {} }

#preload-overlay {
	@apply bg-white fixed w-full h-full top-0 left-0 z-20 {} }

#barba-wrapper {
	@apply relative z-10 {} }

.barba-container {
	@apply w-full {} }


