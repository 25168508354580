.video-wrapper {
	@apply absolute w-full h-full {}
	video {
		@apply object-cover w-full h-full absolute {} } }

section {
	@apply flex justify-center items-center overflow-hidden {}

	&#intro {
		@apply relative {}
		min-height: 50vh;
		@screen md {
			@apply min-h-screen {} }

		.mini-logo {
			@apply fixed z-50 transition-all duration-300 ease-in-out {}
			max-width: 100px;
			top: 25px;
			@screen md {
				max-width: 150px;
				top: 6px; }

			&.hide {
				@apply opacity-0 {}
				top: 15px;
				@screen md {
					max-width: 150px;
					top: 0px; } } }

		.big-logo {
			@apply absolute z-50 transition-all duration-300 cursor-pointer ease-in-out {}
			width: 80%;
			transform: translateY(0);
			max-width: 600px;

			&.hide {
				@apply opacity-0 {}
				transform: translateY(-10%); } } }


	&#gallery.main,
	&#vintec-gallery.main {
		@apply py-0 {} }

	&.main {
		@apply py-6 {}
		p {
			@apply my-1 {}
			max-width: 900px; } } }

.tiles {
	@apply bg-repeat bg-center {}
	background-image: url(/img/tile.png);
	background-size: 50px;
	@screen md {
		background-size: 100px; } }

.floral {
	@apply text-white {}
	box-shadow: inset 0 20px 20px rgba(black, 0.3), inset 0 -20px 20px rgba(black, 0.3);
	background-image: url(/img/floral.jpg);
	background-position: center;
	background-size: 100px;
	@screen md {
		background-size: 200px; } }

.floral-2 {
	@apply text-white {}
	box-shadow: inset 0 20px 20px rgba(black, 0.3), inset 0 -20px 20px rgba(black, 0.3);
	background-image: url(/img/floral-2.jpg);
	background-position: center;
	background-size: 600px;
	@screen md {
		background-size: 1200px; } }

.floral-3 {
	@apply text-darkgreen {}
	box-shadow: inset 0 20px 20px rgba(black, 0.3), inset 0 -20px 20px rgba(black, 0.3);
	background-image: url(/img/floral-3.jpg);
	background-position: center;
	background-size: 600px;
	@screen md {
		background-size: 1200px; } }

.pattern {
	box-shadow: inset 0 20px 20px rgba(black, 0.3), inset 0 -20px 20px rgba(black, 0.3);
	background-image: url(/img/pattern.jpg);
	background-position: center;
	background-size: 300px;
	@screen md {
		background-size: 600px; } }

.leaf {
	box-shadow: inset 0 20px 20px rgba(black, 0.3), inset 0 -20px 20px rgba(black, 0.3);
	background-image: url(/img/leaf.jpg);
	background-position: center;
	background-size: 300px;
	@screen md {
		background-size: 600px; } }

.container {
	@apply px-25 {}
	width: 100%;
	max-width: 1200px;
	@screen md {
		@apply px-6 {} } }

.slideshow {
	@apply absolute w-full h-full {}
	.slide {
		@apply object-cover {} } }

.menu-links {
	@screen md {
		@apply flex {} } }

.menu-link {
	@apply relative flex items-center my-4 {}
	img {
		width: auto;
		height: auto;
		filter: grayscale(1);
		opacity: 0.5; }

	&:hover {
		img {
			opacity: 1;
			filter: grayscale(0); } }

	.title {
		@apply absolute inset-0 w-full text-center h-full flex justify-center items-center z-20 uppercase {}
		@screen mobile {
			@apply text-lg {} } } }

footer {
	&.floral {
		min-height: auto;
		box-shadow: none; }
	@apply py-6 {}
	.footer-nav {
		@apply text-center {}
		@screen md {
			@apply grid grid-cols-4 gap-1 text-left {} } }


	.signup-form {
		max-width: 800px;
		@apply mx-auto {}
		button {
			width: 200px;
			height: 200px; } } }

.frame {
	@apply fixed z-40 pointer-events-none {} }

$frame_mobile_offset: 25px;
$frame_mobile_size: 19px;

$frame_desktop_offset: 70px;
$frame_desktop_size: 38px;

.b-tl {
	top: 0;
	left: 0;
	width: $frame_mobile_offset;
	height: $frame_mobile_offset;
	background-image: url(/img/border-corner.png);
	background-repeat: no-repeat;
	background-size: $frame_mobile_size $frame_mobile_size;
	background-position: right bottom;
	@screen md {
		width: $frame_desktop_offset;
		height: $frame_desktop_offset;
		background-size: $frame_desktop_size $frame_desktop_size; } }

.b-t {
	top: 0;
	left: 24px;
	right: 24px;
	height: $frame_mobile_offset;
	background-image: url(/img/border-x.png);
	background-repeat: repeat-x;
	background-size: $frame_mobile_size $frame_mobile_size;
	background-position: bottom center;
	@screen md {
		left: $frame_desktop_offset;
		right: $frame_desktop_offset;
		height: $frame_desktop_offset;
		background-size: $frame_desktop_size $frame_desktop_size; } }


.b-tr {
	top: 0;
	right: 0;
	width: $frame_mobile_offset;
	height: $frame_mobile_offset;
	background-image: url(/img/border-corner.png);
	background-repeat: no-repeat;
	background-size: $frame_mobile_size $frame_mobile_size;
	background-position: right bottom;
	transform: scaleX(-1);
	@screen md {
		width: $frame_desktop_offset;
		height: $frame_desktop_offset;
		background-size: $frame_desktop_size $frame_desktop_size; } }

.b-l {
	left: 0;
	top: 24px;
	bottom: 24px;
	width: $frame_mobile_offset;
	background-image: url(/img/border-y.png);
	background-repeat: repeat-y;
	background-size: $frame_mobile_size $frame_mobile_size;
	background-position: right center;
	@screen md {
		top: $frame_desktop_offset;
		bottom: $frame_desktop_offset;
		width: $frame_desktop_offset;
		background-size: $frame_desktop_size $frame_desktop_size; } }

.b-r {
	right: 0;
	top: 24px;
	bottom: 24px;
	width: $frame_mobile_offset;
	background-image: url(/img/border-y.png);
	background-repeat: repeat-y;
	background-size: $frame_mobile_size $frame_mobile_size;
	background-position: right center;
	transform: scaleX(-1);
	@screen md {
		top: $frame_desktop_offset;
		bottom: $frame_desktop_offset;
		width: $frame_desktop_offset;
		background-size: $frame_desktop_size $frame_desktop_size; } }

.b-bl {
	bottom: 0;
	left: 0;
	width: $frame_mobile_offset;
	height: $frame_mobile_offset;
	background-image: url(/img/border-corner.png);
	background-repeat: no-repeat;
	background-size: $frame_mobile_size $frame_mobile_size;
	background-position: right bottom;
	transform: scale(1, -1);
	@screen md {
		width: $frame_desktop_offset;
		height: $frame_desktop_offset;
		background-size: $frame_desktop_size $frame_desktop_size; } }

.b-b {
	bottom: 0;
	left: 24px;
	right: 24px;
	height: $frame_mobile_offset;
	background-image: url(/img/border-x.png);
	background-repeat: repeat-x;
	background-size: $frame_mobile_size $frame_mobile_size;
	background-position: bottom center;
	transform: scaleY(-1);
	@screen md {
		left: $frame_desktop_offset;
		right: $frame_desktop_offset;
		height: $frame_desktop_offset;
		background-size: $frame_desktop_size $frame_desktop_size; } }

.b-br {
	bottom: 0;
	right: 0;
	width: $frame_mobile_offset;
	height: $frame_mobile_offset;
	background-image: url(/img/border-corner.png);
	background-repeat: no-repeat;
	background-size: $frame_mobile_size $frame_mobile_size;
	background-position: right bottom;
	transform: scale(-1, -1);
	@screen md {
		width: $frame_desktop_offset;
		height: $frame_desktop_offset;
		background-size: $frame_desktop_size $frame_desktop_size; } }


.gallery {
	@apply my-4 {}
	.grid-item {
		@apply relative {} }
	.gallery-item {
		@apply absolute inset-0 bg-white m-025 bg-cover bg-center bg-repeat cursor-pointer {} } }


.la-vinotheque {
	@apply fixed z-50 {}
	transform: translateX(-50%);
	width: 140px;
	@screen md {
		@apply top-1/2 right-1 {}
		transform: translateY(-50%);
		width: 160px; }

	@screen mobile {
		@apply bottom-2 left-1/2 {} } }


.fancy-bar {
	@apply hidden {}
	height: 37px;
	@screen xl {
		@apply flex {} }
	.fb-left {
		@apply bg-no-repeat bg-left {}
		width: 30px;
		background-image: url(/img/fancy-bar.png); }

	.fb-center {
		@apply bg-center bg-no-repeat leading-none text-sm {}
		padding-top: 12px;
		background-image: url(/img/fancy-bar.png); }

	.fb-right {
		@apply bg-no-repeat bg-right {}
		width: 30px;
		background-image: url(/img/fancy-bar.png); } }

.fancy-bar-left {
	@apply fixed left-0 w-1/2 flex justify-center items-center z-50 uppercase {}
	top: 23px; }

.fancy-bar-right {
	@apply fixed right-0 w-1/2 flex justify-center items-center z-50 uppercase {}
	top: 23px; }
