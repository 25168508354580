//typography
h1, h2, h3, h4 {
	@apply font-headings text-xl leading-none {}
	@screen md {
		@apply text-xxl mb-15 {} } }

h3 {
	@apply font-body text-lg {}
	@screen md {
		@apply text-xl {} } }

h4 {
	@apply font-body text-lg {} }

p {
	&:first-of-type {
		@apply mt-1 {} }
	&:last-of-type {
		@apply mb-1 {} } }
